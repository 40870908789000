import { ChangeEvent, Dispatch, FC, SetStateAction } from 'react';
import { Carousel } from 'src/components/Carousel';

import { ReactComponent as ImgIcon } from 'src/assets/icons/kit/img.svg';
import cn from 'classnames';
import { BasketIconButton } from 'src/components/IconButton/BasketIconButton';

interface ProductImagesCarouselProps {
  images: (string | File)[];
  setImages: Dispatch<SetStateAction<(string | File)[]>>;
}

export const ProductImagesCarousel: FC<ProductImagesCarouselProps> = ({ images, setImages }) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e?.target?.files?.length) {
      return;
    }

    const fileListArr = Array.from(e?.target?.files).map(image => image);
    setImages(prev => [...prev, ...fileListArr]);
  };

  const getSrc = (img: File | string) => {
    if (typeof img === 'string') {
      return img;
    }

    return URL.createObjectURL(img as File);
  };

  const handleRemove = index => {
    setImages(prev => prev.filter((_, i) => i !== index));
  };

  return (
    <Carousel itemWidth={80}>
      {images.map((img, i) => (
        <div
          key={`carousel-products-image-${i}`}
          className={
            'min-w-[5rem] w-20 h-20 flex items-center justify-center rounded-xl ' +
            'relative overflow-hidden'
          }
        >
          <BasketIconButton
            image="basket"
            onClick={() => handleRemove(i)}
            variant="sm"
            classNames="absolute top-1.5 right-1.5"
          />
          <BasketIconButton
            image="loupe"
            onClick={() => handleRemove(i)}
            variant="sm"
            classNames="absolute top-1.5 left-1.5"
          />
          <img src={getSrc(img)} alt="" className="w-full h-full object-cover" />
        </div>
      ))}
      <label
        className={cn(
          'min-w-[5rem] w-20 h-20 flex items-center justify-center bg-grey-800',
          'rounded border border-dashed border-grey-100 cursor-pointer ',
          'transition-full hover:border-solid group',
        )}
      >
        <ImgIcon className="w-8 h-8 transition-full fill-grey-100 group-hover:fill-turquoise-100" />

        <input
          multiple
          onChange={handleChange}
          className="hidden"
          type="file"
          accept="image/png, image/jpeg, image/jpg"
        />
      </label>
    </Carousel>
  );
};
