import { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import TokenService from 'src/services/TokenService';

interface ProtectedRouteProps {
  children: ReactNode;
}

export const ProtectedRoute: FC<ProtectedRouteProps> = ({ children }) => {
  const user = TokenService.getUser();

  // if (!user) {
  //   return <Navigate to="/" />;
  // }

  return <>{children}</>;
};
