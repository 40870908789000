import { FC, useEffect, useMemo, useReducer } from 'react';
import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS, Step } from 'react-joyride';
import { useAppSelector } from 'src/hooks/useRedux';
import { OnBoardingInfoType } from 'src/config/types';
import OnBoardingService from 'src/services/OnBoardingService';

interface OnBoardingProps {
  steps: Step[];
  id: number;
}

export const OnBoarding: FC<OnBoardingProps> = ({ steps, id }) => {
  const {
    common: { isOpenTariffEndModal },
    user: {
      onBoarding: { onboardingSteps },
      user,
      isLoadingUser,
      isLoadingOnBoarding,
    },
  } = useAppSelector(state => state);

  const curUserOnboarding: OnBoardingInfoType = useMemo(
    () => user?.onbordingsInfo?.find(el => el.onbordingId === id) || ({} as OnBoardingInfoType),
    [id, user],
  );

  const curOnBoardingSteps = useMemo(
    () => onboardingSteps?.filter(el => el.onboardingId === id) || [],
    [id, onboardingSteps],
  );

  useEffect(() => {
    if (!isLoadingUser && !isLoadingOnBoarding && !curUserOnboarding?.completed) {
      startOnBoarding();
    }
  }, [curUserOnboarding, isLoadingUser, isLoadingOnBoarding]);

  useEffect(() => {
    if (isOpenTariffEndModal) {
      dispatch({ type: 'STOP' });
    }
  }, [isOpenTariffEndModal]);

  const INITIAL_STATE = {
    key: new Date(),
    run: false,
    continuous: true,
    loading: false,
    hideCloseButton: true,
    showSkipButton: true,
    disableOverlayClose: true,
    stepIndex: 0,
    steps,
    locale: {
      back: 'Назад',
      close: 'Закрыть',
      last: 'Закрыть',
      next: 'Далее',
      skip: 'Пропустить',
    },
    styles: {
      options: {
        zIndex: 10000,
      },
      tooltip: {
        padding: '18px 10px',
        borderRadius: '10px',
      },
      tooltipContent: {
        padding: '0 8px',
        textAlign: 'start',
        fontFamily: 'Gilroy Regular, sans-serif',
        fontSize: '14px',
      },
      buttonNext: {
        fontFamily: 'Gilroy Medium, sans-serif',
        backgroundColor: '#7E52CB',
        fontSize: '14px',
        marginRight: '8px',
      },
      buttonBack: {
        fontFamily: 'Gilroy Medium, sans-serif',
        color: '#7E52CB',
        fontSize: '14px',
      },
      buttonSkip: {
        fontFamily: 'Gilroy Medium, sans-serif',
        fontSize: '14px',
      },
    },
  };

  const index = useMemo(() => {
    if (!curUserOnboarding?.stepId) {
      return 0;
    }

    const findIndex = curOnBoardingSteps.findIndex(el => el.id === curUserOnboarding?.stepId);

    if (findIndex === -1) {
      return 0;
    }

    return curUserOnboarding?.buttonId === 2 ? findIndex - 1 : findIndex + 1;
  }, [curUserOnboarding, curOnBoardingSteps]);

  const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'START':
        return { ...state, run: true };
      case 'RESET':
        return { ...state, stepIndex: 0 };
      case 'STOP':
        return { ...state, run: false };
      case 'NEXT_OR_PREV':
        return { ...state, ...action.payload };
      case 'RESTART':
        return {
          ...state,
          stepIndex: index,
          run: true,
          loading: false,
          key: new Date(),
        };
      default:
        return state;
    }
  };

  const [tourState, dispatch] = useReducer(reducer, INITIAL_STATE);

  const startOnBoarding = () => {
    dispatch({ type: 'RESTART' });
  };

  const saveOnBoardingData = async (params: { onboardingStepId: number; buttonId: number }) => {
    try {
      await OnBoardingService.createUsersStep(params);
    } catch (e) {}
  };

  const handleJoyrideCallback = async (data: CallBackProps) => {
    const { action, index, type, status } = data;
    const onboardingStepId = curOnBoardingSteps[index]?.id;
    if (
      action === ACTIONS.CLOSE ||
      (status === STATUS.SKIPPED && tourState.run) ||
      status === STATUS.FINISHED
    ) {
      if (status === STATUS.FINISHED) {
        await saveOnBoardingData({ buttonId: 4, onboardingStepId });
      }

      if (status === STATUS.SKIPPED) {
        await saveOnBoardingData({ buttonId: 3, onboardingStepId });
      }

      dispatch({ type: 'STOP' });
    } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      if (action === ACTIONS.PREV) {
        await saveOnBoardingData({
          buttonId: 2,
          onboardingStepId,
        });
      }

      if (action !== ACTIONS.PREV) {
        await saveOnBoardingData({ buttonId: 1, onboardingStepId });
      }

      dispatch({
        type: 'NEXT_OR_PREV',
        payload: { stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) },
      });
    }
  };

  return (
    <>
      <Joyride {...tourState} callback={handleJoyrideCallback} />
    </>
  );
};
