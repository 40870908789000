import { ChangeEvent, Dispatch, FC, Fragment, SetStateAction, useEffect, useState } from 'react';
import { Button } from 'src/components/kit/Button';
import { useForm } from 'react-hook-form';
import { AddButton } from 'src/components/AddButton';
import { ControlledInput } from 'src/components/kit/ControlledInput';
import { Input } from 'src/components/kit/Input';
import { ProductStatsCarousel } from 'src/components/ProductStatsCarousel';
import { StatItemType, StatType } from 'src/config/types';

import { ReactComponent as AddFileIcon } from 'src/assets/icons/kit/add-file.svg';
import cn from 'classnames';

interface StatsAddFromProps {
  setStats: Dispatch<SetStateAction<StatType[]>>;
  statIndex: number;
  stats?: StatType[];
  onClose: () => void;
  classNames?: string;
}

export const StatsAddFrom: FC<StatsAddFromProps> = ({
  setStats,
  statIndex,
  stats,
  onClose,
  classNames,
}) => {
  const [newStatIndex, setNewStatIndex] = useState<number>(0);
  const [curStats, setCurStats] = useState<StatItemType[]>(
    stats?.[statIndex]?.parameterValues ||
      ([
        {
          valueName: '',
          flag: 'insert',
        },
      ] as StatItemType[]),
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ name: string }>({
    mode: 'all',
    defaultValues: { name: stats?.[statIndex]?.parameterName || '' },
  });

  const handleChangeName = (valueName: string, index: number) => {
    setCurStats(prev =>
      prev.map((el, i) => (i === index ? { ...el, valueName, flag: el.flag || 'update' } : el)),
    );
  };

  const handleChangeImage = (index: number, e?: ChangeEvent<HTMLInputElement>) => {
    const image = (e?.target?.files?.[0] as File) || undefined;

    setCurStats(prev =>
      prev.map((el, i) => (i === index ? { ...el, image, flag: el.flag || 'update' } : el)),
    );
  };

  const addStat = e => {
    e.preventDefault();
    setCurStats(prev => [...prev, { flag: 'insert' }]);
    setNewStatIndex(curStats.length);
  };

  useEffect(() => {
    if (!newStatIndex) {
      return;
    }
    const allStatInputs = document.querySelector(`.stat-input-${newStatIndex}`);
    if (allStatInputs) {
      (allStatInputs as HTMLInputElement).focus();
    }
  }, [newStatIndex]);

  const handleRemoveStat = index => {
    setCurStats(prev => {
      let tempPrev;
      if (prev[index].flag === 'insert') {
        tempPrev = prev.filter((_, i) => i !== index);
      } else {
        tempPrev = [...prev];
        tempPrev[index] = { ...tempPrev[index], flag: 'delete' };
      }

      return tempPrev;
    });
  };

  const checkDelete = () => {
    const isRemove = curStats?.filter(el => el.flag !== 'delete');
    return !isRemove?.length;
  };

  const checkCarousel = () => {
    if (!curStats?.length) {
      return false;
    }
    let needShow = false;

    curStats?.forEach(el => {
      if ((el.image || el.valueName) && el.flag !== 'delete') {
        needShow = true;
      }
    });

    return needShow;
  };

  const onSubmit = handleSubmit(async data => {
    if (!curStats?.length) {
      onClose();
      return;
    }

    const newStat: StatType = {
      parameterId: stats?.[statIndex]?.parameterId || '',
      parameterName: data.name || '',
      flag: stats?.[statIndex]?.parameterId ? (checkDelete() ? 'delete' : 'update') : 'insert',
      parameterValues: curStats,
    };

    setStats(prev => {
      const tempPrev = [...prev];
      if (tempPrev?.[statIndex]) {
        tempPrev[statIndex] = newStat;
      } else {
        tempPrev.push(newStat);
      }
      return tempPrev;
    });

    onClose();
  });

  const getTitle = (name: string) => (
    <div className="w-full mb-2.5 text-2xl font-gilroy-500">{name}</div>
  );

  return (
    <form
      className={cn(
        'max-w-[32.5rem] w-full t-lg:w-[32.5rem] mx-auto flex flex-col gap-8 items-center',
        classNames,
      )}
      onSubmit={onSubmit}
    >
      {getTitle('Добавить название')}

      <Input
        register={register}
        data={{ placeholder: 'Название', name: 'name', required: true }}
        errors={errors}
      />

      {checkCarousel() ? (
        <ProductStatsCarousel
          key={`product-stat-show-${statIndex}`}
          stat={curStats}
          handleRemove={handleRemoveStat}
        />
      ) : null}

      {getTitle('Добавить характеристику')}

      {curStats.map((stat, i) => {
        const key = `add-stats-${i}`;

        if (stat.flag === 'delete') {
          return <Fragment key={key} />;
        }

        return (
          <div
            className={cn(
              {
                'flex-col-reverse t-sm:flex-row': !Boolean(stat?.image),
                'flex-col-reverse': Boolean(stat?.image),
              },
              'flex w-full gap-3',
            )}
            key={key}
          >
            <ControlledInput
              onChange={str => handleChangeName(str, i)}
              value={stat?.valueName}
              placeholder="Название"
              classNames={`w-full stat-input-${i}`}
            />
            <label>
              {!stat?.image ? (
                <AddButton
                  name="Изображение"
                  classNames="t-sm:min-w-[11rem] w-full t-sm:w-[11rem]"
                />
              ) : null}

              <input
                multiple={false}
                onChange={e => handleChangeImage(i, e)}
                className="hidden"
                type="file"
                accept="image/png, image/jpeg, image/jpg"
              />
            </label>
          </div>
        );
      })}

      <Button variant="turquoise-light" size="small" classNames="ml-auto" onClick={addStat}>
        Добавить новую характеристику
        <AddFileIcon className="w-4 h-4" />
      </Button>

      <div className="flex t-sm:w-fit t-sm:flex-row flex-col-reverse w-full gap-5 mt-10">
        <Button
          variant="turquoise-outline"
          onClick={e => {
            e?.stopPropagation();
            onClose();
          }}
        >
          Отмена
        </Button>
        <Button variant="turquoise" type="submit">
          Сохранить
        </Button>
      </div>
    </form>
  );
};
