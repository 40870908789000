import { FC, useState } from 'react';
import { DashboardContainer } from 'src/containers/DashboardContainer';
import { ShopCard } from 'src/components/Cards/ShopCard';
import { Button } from 'src/components/kit/Button';
import { Modal } from 'src/components/kit/Modal';
import { CreateShopForm } from 'src/components/Forms/CreateShopForm';
import { Loader } from 'src/components/Loader';
import { OnBoarding } from 'src/components/OnBoarding';
import { useAppSelector } from 'src/hooks/useRedux';
import { commonOnBoardingState } from 'src/config/onBoardingData';

export const Shops: FC = () => {
  const {
    shops: { isLoadingShops, shops },
  } = useAppSelector(state => state);

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const openModal = () => setIsOpenModal(true);
  const closeModal = () => setIsOpenModal(false);

  return (
    <DashboardContainer
      title="Мои магазины"
      description="Здесь вы можете изменить данные вашего магазина"
    >
      <OnBoarding steps={commonOnBoardingState} id={1} />

      {isLoadingShops ? <Loader classNames="mx-auto my-20 w-1/3 t-lg:w-1/3" /> : null}

      {!isLoadingShops && !shops.length ? (
        <div className="my-20 text-center text-xl t-sm:text-3xl font-gilroy-500">
          У вас нет активных магазинов
        </div>
      ) : null}

      {!isLoadingShops && shops.length ? (
        <div className="flex flex-col gap-5 t-lg:gap-8 mb-10 t-lg:mb-12">
          {shops.map((shop, i) => (
            <ShopCard key={`shop-${i}`} data={shop} classNames="w-full" />
          ))}
        </div>
      ) : null}

      {!isLoadingShops ? (
        <Button
          variant="turquoise"
          id="create-shop-button-onboarding"
          classNames="mx-auto create-shop-button"
          onClick={openModal}
        >
          Добавить новый магазин
        </Button>
      ) : null}

      <Modal isOpen={isOpenModal} onClose={closeModal}>
        <CreateShopForm onSave={closeModal} />
      </Modal>
    </DashboardContainer>
  );
};
