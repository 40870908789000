import { FC } from 'react';

import cn from 'classnames';

interface LoaderProps {
  stroke?: string;
  strokeWidth?: string;
  classNames?: string;
}

export const Loader: FC<LoaderProps> = ({ stroke, strokeWidth, classNames }) => {
  return (
    <div className={cn('relative', classNames)}>
      <svg className="before:content-[''] before:block before:pt-[100]" viewBox="25 25 50 50">
        <circle
          className="path"
          cx="50"
          cy="50"
          r="20"
          fill="none"
          strokeWidth={strokeWidth || '3'}
          strokeMiterlimit="10"
          style={{ stroke: stroke || '#7E52CB' }}
        />
      </svg>
    </div>
  );
};
