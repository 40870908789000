import { ChangeEvent, FC } from 'react';

import cn from 'classnames';

interface ControlledInputProps {
  classNames?: string;
  type?: 'input' | 'textarea';
  onChange: (str: string) => void;
  value?: string;
  placeholder: string;
}

export const ControlledInput: FC<ControlledInputProps> = ({
  classNames,
  type = 'input',
  onChange,
  value,
  placeholder,
}) => {
  const className = cn(
    classNames,
    { 'resize-none': type === 'textarea' },
    'placeholder-shown:bg-grey-800 hover:shadow-turquoise-400',
    'rounded-xl px-8 py-3 font-gilroy-400 text-base border-2',
    'placeholder-grey-100 border-grey-900',
  );

  const handleChange = (e: ChangeEvent) => {
    onChange((e.target as HTMLInputElement).value);
  };

  return type === 'input' ? (
    <input
      placeholder={placeholder}
      type="text"
      className={className}
      onChange={handleChange}
      value={value}
    />
  ) : (
    <textarea rows={3} className={className} onChange={handleChange} {...(value && { value })} />
  );
};
