import { ChangeEvent, FC, useEffect, useState } from 'react';
import { InputType } from 'src/config/types';
import { FieldErrors, UseFormSetValue } from 'react-hook-form';
import { formatBytes } from 'src/utils';

import { ReactComponent as PaperClipIcon } from 'src/assets/icons/kit/paper-clip.svg';
import { ReactComponent as BasketIcon } from 'src/assets/icons/kit/basket.svg';

import cn from 'classnames';

interface InputFileProps {
  data: InputType;
  id?: string;
  variant?: 'logo' | 'image';
  defaultValue?: string;
  clearErrors: (name: any) => void;
  setValue: UseFormSetValue<any>;
  register: (name: string, RegisterOptions?) => { onChange; onBlur; name; ref };
  errors: FieldErrors;
  classNames?: string;
}

export const InputFile: FC<InputFileProps> = ({
  register,
  id,
  variant = 'image',
  defaultValue,
  clearErrors,
  data,
  setValue,
  errors,
  classNames,
}) => {
  const isError = !!errors?.[data.name];

  const { onBlur, name, ref } = register(data.name, {
    validate: val => {
      if (typeof val === 'string' && !val && data.required) {
        return 'Поле не может быть пустым!';
      }
      if (typeof val !== 'string' && !(val as File)?.name && data.required) {
        return 'Поле не может быть пустым!';
      }
    },
  });
  const [image, setImage] = useState<File | string | null>(null);

  useEffect(() => {
    if (defaultValue) {
      setImage(defaultValue);
      setValue(data.name, defaultValue);
    }
  }, []);

  const handleDeleteFile = e => {
    e.preventDefault();
    setImage(null);
    setValue(data.name, '');
  };

  const saveFile = (file: File) => {
    if (file.size > 41943040) {
      return;
    }

    setImage(file);
    setValue(data.name, file);
    clearErrors(data.name);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e?.target?.files?.length) {
      return;
    }
    saveFile(e.target.files[0]);
  };

  const getSrc = (img: File | string) => {
    if (typeof image === 'string') {
      return image;
    }

    return URL.createObjectURL(img as File);
  };

  const handleDrag = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      saveFile(e.dataTransfer.files[0]);
    }
  };

  return (
    <>
      <label
        id={id}
        onDrop={handleDrop}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        className={cn(
          {
            'hover:bg-white-200 border-2 border-dashed border-grey-400': !image && !isError,
            'bg-white font-gilroy-400 text-grey-200 ': !image && !isError,
            'py-10': !image,
            'text-pink-100 bg-white border-pink-100 border-2 border-solid': isError,
            'relative overflow-hidden text-white gap-1 py-7': !!image,
          },
          'w-full rounded-3xl flex flex-col items-center min-h-[12rem] cursor-pointer',
          classNames,
        )}
      >
        {image ? (
          <>
            <img
              className={cn('absolute inset-0 w-full h-full object-cover')}
              src={getSrc(image)}
              alt=""
            />
            <div
              className={
                'bg-gradient-black-100 bottom-0 left-0 w-full h-1/2 ' +
                'opacity-50 absolute opacity-90'
              }
            />
            {variant === 'logo' ? (
              <>
                <div className="bg-grey-100 inset-0 absolute opacity-70" />
                <img
                  src={getSrc(image)}
                  className="w-28 h-28 border-2 border-white relative bg-white rounded-full"
                  alt=""
                />
              </>
            ) : null}

            <div
              onClick={handleDeleteFile}
              className={
                'bg-white flex items-center justify-center rounded-full ' +
                'w-8 h-8 absolute top-5 right-5'
              }
            >
              <BasketIcon className="w-4 h-4 fill-grey-100" />
            </div>
            {typeof image !== 'string' ? (
              <div className="font-gilroy-500 relative mt-auto">{image.name}</div>
            ) : null}
            {typeof image !== 'string' ? (
              <div className="font-gilroy-400 text-small relative">{formatBytes(image.size)}</div>
            ) : null}
          </>
        ) : null}

        {!image ? (
          <>
            <div>Перетащите изображение сюда</div>
            <div className="text-small mb-5">Рекомендуемый формат JPEG, JPG, PNG, 300x300 px</div>
            <div className="text-xs mb-2.5">Или нажмите на кнопку</div>
            <div
              className={
                'bg-violet-100 rounded-full flex items-center justify-center ' +
                'font-gilroy-600 text-turquoise-100 gap-3 t-sm:w-fit py-2 px-6 text-xs'
              }
            >
              Прикрепить изображение
              <PaperClipIcon className="w-5 h-5 t-sm:w-3.5 t-sm:h-3.5 fill-turquoise-100" />
            </div>
          </>
        ) : null}

        {isError ? <div className="hook-form-error" /> : null}

        <input
          onChange={handleChange}
          onBlur={onBlur}
          name={name}
          ref={ref}
          multiple={false}
          className="hidden"
          accept="image/png, image/jpeg, image/jpg"
          type="file"
        />
      </label>
    </>
  );
};
