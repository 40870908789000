import { FC } from 'react';

import { ReactComponent as PlusIcon } from 'src/assets/icons/kit/plus.svg';
import cn from 'classnames';

interface AddButtonProps {
  onClick?: () => void;
  id?: string;
  name: string;
  classNames?: string;
}

export const AddButton: FC<AddButtonProps> = ({ onClick, id, name, classNames }) => {
  return (
    <div
      id={id}
      className={cn(
        'flex bg-grey-800 border-2 border-dashed rounded-xl h-14 items-center',
        'cursor-pointer justify-center gap-5 w-full px-4',
        classNames,
      )}
      onClick={onClick}
    >
      <PlusIcon className="fill-grey-100 w-4 h-4 min-w-[1rem]" />
      <div className="text-grey-100 font-gilroy-400 w-fit">{name}</div>
    </div>
  );
};
