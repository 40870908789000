import { FC, useState } from 'react';
import { Button } from 'src/components/kit/Button';
import { Tag } from 'src/components/kit/Tag';
import { GroupAddForm } from 'src/components/Forms/GroupAddForm';
import { Modal } from 'src/components/kit/Modal';
import { useParams } from 'react-router';
import { declOfNum } from 'src/utils';
import { DeleteModal } from 'src/components/Modals/DeleteModal';
import { useAppDispatch } from 'src/hooks/useRedux';
import { fetchGroups } from 'src/store/groups/actions';
import { AppToolTip } from 'src/components/AppToolTip';
import { GroupType } from 'src/config/types';
import GroupService from 'src/services/GroupService';

import { ReactComponent as AddFileIcon } from 'src/assets/icons/kit/add-file.svg';
import { ReactComponent as CloseIcon } from 'src/assets/icons/kit/close.svg';

import cn from 'classnames';

interface GroupCardProps {
  data: GroupType;
  classNames?: string;
}

export const GroupCard: FC<GroupCardProps> = ({ data, classNames }) => {
  const { shopId } = useParams();
  const dispatch = useAppDispatch();

  const [isOpenAddModal, setIsOpenAddModal] = useState<boolean>(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);

  const openAddModal = () => setIsOpenAddModal(true);
  const closeAddModal = () => setIsOpenAddModal(false);

  const openDeleteModal = () => setIsOpenDeleteModal(true);
  const closeDeleteModal = () => setIsOpenDeleteModal(false);

  const handleDeleteGroup = async () => {
    await GroupService.deleteGroup(data.id, shopId || '');
    await dispatch(fetchGroups(shopId || ''));
  };
  const generateDeleteText = () => {
    const productsText = data.productsCount
      ? `Данная группа сдержит ${data.productsCount} активных товаров.\n`
      : '';

    return `${productsText}Вы точно хотите удалить группу?`;
  };

  return (
    <div
      className={cn(
        'w-full rounded-4xl bg-white p-4 t-lg:p-8 hover:shadow-turquoise-100 flex flex-col',
        classNames,
      )}
    >
      <div className="font-gilroy-500 text-lg t-lg:text-2xl mb-1 flex justify-between gap-4 items-center">
        <AppToolTip classNames="truncate" id="group-card-name-tooltip" text={data.name} />
        <CloseIcon className="w-4 h-4 min-w-[1rem] cursor-pointer" onClick={openDeleteModal} />
      </div>

      <div className="font-gilroy-400 text-grey-100 text-sm t-lg:text-base mb-5">
        {`В группе ${data.productsCount ? data.productsCount : 'нет'} ${declOfNum(
          data.productsCount,
          ['товар', 'товара', 'товаров'],
        )}`}
      </div>

      <div className="font-gilroy-500 text-xs ml-2 mb-1">
        <div className="text-turquoise-100">Подгруппы</div>
        {!data.subgroups.length ? <div className="text-grey-100">Нет активных подгрупп</div> : null}
      </div>

      {data.subgroups.length ? (
        <div className="flex gap-1.5 flex-wrap">
          {data.subgroups.map((subgroup, i) => (
            <Tag
              key={`subgroup-${i}`}
              onClick={openAddModal}
              tag={{ name: subgroup.name, value: '' }}
              classNames="max-w-[10rem] truncate"
            />
          ))}
        </div>
      ) : (
        <Button
          variant="turquoise-light"
          size="small"
          icon={<AddFileIcon />}
          onClick={openAddModal}
          classNames="w-fit"
        >
          Добавить подгруппу
        </Button>
      )}

      <div className="flex flex-col gap-2.5 t-sm:flex-row items-center justify-between w-full pt-8 mt-auto">
        <Button
          buttonVariant="link"
          variant="turquoise"
          classNames="w-full t-sm:w-fit"
          href={`/dashboard/shops/${shopId}/${data.id}`}
        >
          Перейти
        </Button>
        <Button variant="turquoise-outline" classNames="w-full t-sm:w-fit" onClick={openAddModal}>
          Изменить
        </Button>
      </div>

      {isOpenDeleteModal ? (
        <Modal isOpen={isOpenDeleteModal} onClose={closeDeleteModal}>
          <DeleteModal
            text={generateDeleteText()}
            handleDelete={handleDeleteGroup}
            handleClose={closeDeleteModal}
          />
        </Modal>
      ) : null}

      {isOpenAddModal ? (
        <Modal isOpen={isOpenAddModal} onClose={closeAddModal}>
          <GroupAddForm defaultValues={data} onSave={closeAddModal} shopId={shopId || ''} />
        </Modal>
      ) : null}
    </div>
  );
};
