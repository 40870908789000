import { FC, useState } from 'react';
import { ProductType } from 'src/config/types';
import { GradientBorder } from 'src/components/kit/GradientBorder';
import { Button } from 'src/components/kit/Button';
import { numberWithSpaces } from 'src/utils';
import { Modal } from 'src/components/kit/Modal';
import { ProductAddForm } from 'src/components/Forms/ProductAddForm';
import { Discount } from 'src/components/kit/Discount';
import { BasketIconButton } from 'src/components/IconButton/BasketIconButton';
import { useParams } from 'react-router';
import { useAppDispatch } from 'src/hooks/useRedux';
import { fetchProducts } from 'src/store/products/actions';
import { DeleteModal } from 'src/components/Modals/DeleteModal';
import ProductService from 'src/services/ProductService';

import { ReactComponent as EditIcon } from 'src/assets/icons/kit/edit.svg';

import cn from 'classnames';
import { Tooltip } from 'react-tooltip';

interface ProductSmallCardProps {
  data: ProductType;
  classNames?: string;
}

export const ProductSmallCard: FC<ProductSmallCardProps> = ({ data, classNames }) => {
  const { shopId, groupId } = useParams();
  const dispatch = useAppDispatch();

  const [isOpenAddModal, setIsOpenAddModal] = useState<boolean>(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);

  const openAddModal = () => setIsOpenAddModal(true);
  const closeAddModal = () => setIsOpenAddModal(false);

  const openDeleteModal = () => setIsOpenDeleteModal(true);
  const closeDeleteModal = () => setIsOpenDeleteModal(false);

  const handleDeleteProduct = async () => {
    await ProductService.deleteProduct(data.id, shopId || '');
    await dispatch(fetchProducts({ shopId: shopId || '', groupIds: groupId || '' }));
  };

  return (
    <>
      <GradientBorder
        borderWidth="2px"
        borderGradient={data.isActive ? 'bg-turquoise-100' : 'bg-grey-300 hover:bg-white'}
        classNames="rounded-xl"
      >
        <div
          className={cn(
            {
              'bg-white': data.isActive,
              'hover:bg-white hover:shadow-turquoise-100': !data.isActive,
            },
            'p-2 rounded-xl group transition-full w-full h-full flex flex-col',
            classNames,
          )}
        >
          <div className="relative w-full h-20 mb-2.5">
            <img
              src={data.image}
              alt=""
              className={cn(
                { 'opacity-30 group-hover:opacity-100': !data.isActive },
                'object-cover w-full h-full transition-full',
              )}
            />
            <BasketIconButton
              image="basket"
              onClick={openDeleteModal}
              classNames="absolute top-0 right-0"
            />
            <Discount discount={data.discount} size="sm" classNames="absolute bottom-0 right-0" />
          </div>

          <div className="flex gap-2 items-end justify-between">
            <div className="flex flex-col gap-1" style={{ width: 'calc(100% - 40px)' }}>
              <div
                className="text-small font-gilroy-500 mb-1 truncate"
                data-tooltip-id={`product-name-${data.id}-tooltip`}
                data-tooltip-content={data.name}
              >
                {data.name}
              </div>

              <div className="h-2 font-gilroy-400 line-through text-grey-100 text-extra-small-1">
                {data.oldPrice !== data.newPrice
                  ? `${numberWithSpaces(data.oldPrice)} ${data.currency || '₽'}`
                  : ''}
              </div>
              <div
                className={cn(
                  {
                    'text-turquoise-100': data.isActive,
                    'text-grey-100 group-hover:text-turquoise-100': !data.isActive,
                  },
                  'font-gilroy-700 text-sm-1 transition-full',
                )}
              >
                {`${numberWithSpaces(data.newPrice)} ${data.currency || '₽'}`}
              </div>
            </div>
            <Button
              variant="turquoise-icon"
              classNames={cn({ 'bg-white-100': data.isActive, 'bg-white': !data.isActive })}
              size="icon"
              onClick={openAddModal}
            >
              <EditIcon className="w-4 h-4" />
            </Button>
          </div>
        </div>
      </GradientBorder>

      <Tooltip id={`product-name-${data.id}-tooltip`} className="tooltip-white" />

      {isOpenDeleteModal ? (
        <Modal isOpen={isOpenDeleteModal} onClose={closeDeleteModal}>
          <DeleteModal
            text="Вы точно хотите удалить товар?"
            handleDelete={handleDeleteProduct}
            handleClose={closeDeleteModal}
          />
        </Modal>
      ) : null}

      {isOpenAddModal ? (
        <Modal isOpen={isOpenAddModal} onClose={closeAddModal}>
          <ProductAddForm
            defaultValues={data}
            onSave={closeAddModal}
            shopId={data.shopId}
            groupId={data.groupId}
          />
        </Modal>
      ) : null}
    </>
  );
};
