import { FC, useEffect, useRef, useState } from 'react';
import { useClickOutside } from 'src/hooks/useClickOutside';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import { logout } from 'src/store/user';
import { Select } from 'src/components/kit/Select';
import { selectShop } from 'src/store/shops';
import { ControlledInput } from 'src/components/kit/ControlledInput';
import { setGlobalSearch } from 'src/store/common';
import { useLocation } from 'react-router';
import { SelectItemType } from 'src/config/types';
import { Link, useNavigate } from 'react-router-dom';
import { headItems } from 'src/config/data';
import AuthService from 'src/services/AuthService';
import TokenService from 'src/services/TokenService';

import { ReactComponent as BurgerIcon } from 'src/assets/icons/kit/burger.svg';
import { ReactComponent as CloseIcon } from 'src/assets/icons/kit/close.svg';
import { ReactComponent as ExitIcon } from 'src/assets/icons/kit/exit.svg';
import { ReactComponent as ShopIcon } from 'src/assets/icons/kit/shop.svg';
import logo from 'src/assets/icons/logo.svg';

import cn from 'classnames';
import { Logo } from 'src/components/Logo';

interface HeadProps {
  isSupport?: boolean;
  onlyLogo?: boolean;
}

export const Head: FC<HeadProps> = ({ isSupport, onlyLogo }) => {
  const {
    orders: { newOrders },
    common: { globalSearch },
    shops: { shops, selectedShop },
  } = useAppSelector(state => state);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLDivElement>(null);

  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [activeShadow, setActiveShadow] = useState<boolean>(false);
  const [searchActive, setSearchActive] = useState(false);

  const openMenu = () => setMenuOpen(true);
  const closeMenu = () => setMenuOpen(false);

  const handleChangeSearch = (search: string) => dispatch(setGlobalSearch(search));

  const currentRoute = headItems.find(item => item.route === location.pathname);

  const shopsList: SelectItemType[] = shops.map(shop => ({
    name: shop.name,
    value: shop.id,
    image: shop.image || logo,
  }));

  const handleChangeSelectedShop = (item: SelectItemType) => {
    const shop = shops.find(shop => shop.id === item.value);

    if (!shop) {
      return;
    }

    dispatch(selectShop(shop));

    if (location.pathname.includes('dashboard/shops')) {
      const splitLocation = location.pathname.split('/');
      if (splitLocation?.[3]) {
        navigate(`/dashboard/shops/${shop.id}`);
      }
    }
  };

  const handleScroll = e => {
    setActiveShadow((e.target as HTMLDivElement).scrollTop > 10);

    if (document.querySelector('#global-search')) {
      setSearchActive((e.target as HTMLDivElement).scrollTop > 80);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const mainScrollContainer = document.querySelector('#main-scroll');
      mainScrollContainer?.addEventListener('scroll', handleScroll);
    }

    return () => {
      const mainScrollContainer = document.querySelector('#main-scroll');
      mainScrollContainer?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (globalSearch) {
      dispatch(setGlobalSearch(''));
    }

    if (!document.querySelector('#global-search')) {
      setSearchActive(false);
    }
  }, [location]);

  useEffect(() => {
    setActiveShadow(false);
  }, [window.location]);

  useClickOutside(ref, closeMenu);

  const styles = 'px-5 py-8 cursor-pointer font-gilroy-500 text-lg';

  const handleLogout = async () => {
    await AuthService.logout();
    TokenService.removeUser();
    dispatch(logout());
    navigate('/');
  };

  return (
    <div
      className={cn(
        { 'shadow-black-300': activeShadow },
        'fixed top-0 left-0 z-50 w-full bg-white-200',
      )}
    >
      {isSupport ? (
        <div className="absolute bg-white top-0 right-[76%] h-full w-full hidden t-lg:block" />
      ) : null}

      <div className="mx-auto max-w-[80rem] w-full relative">
        <div className="w-full flex px-4 t-lg:px-5 h-16 cursor-default">
          {isSupport ? (
            <div className="absolute bg-white top-0 left-0 h-full w-96 hidden t-lg:block" />
          ) : null}

          <Link to="/" className="flex items-center gap-2 relative">
            <Logo small />
            <div className="text-lg text-violet-200 font-gilroy-700 hidden t-lg:block">
              АГРЕГАТОР
            </div>
          </Link>

          {!onlyLogo ? (
            <>
              <div className="flex items-center ml-auto relative z-10">
                {shopsList?.length ? (
                  <div className="min-w-[8rem] max-w-[8rem] m-md:max-w-[14rem] d-xs:max-w-[20rem] mr-4">
                    <Select
                      data={{
                        name: 'name',
                        list: shopsList,
                      }}
                      setStateValue={handleChangeSelectedShop}
                      value={{
                        name: selectedShop?.name || '',
                        value: selectedShop?.id || '',
                        image: selectedShop?.image || logo,
                      }}
                      size="small"
                      variant="white"
                    />
                  </div>
                ) : null}

                <Link
                  to="/dashboard/orders"
                  id="orders-button-onboarding"
                  className={
                    'bg-violet-200 px-5 py-2 rounded-full cursor-pointer mr-6 h-8 flex ' +
                    'items-center gap-2'
                  }
                >
                  <div className="text-white hidden t-lg:block font-gilroy-600 text-xs">Заказы</div>
                  <div className="w-4 h-4 relative">
                    <ShopIcon className="w-full h-full fill-white" />
                    {newOrders ? (
                      <div
                        className={
                          'min-w-[0.75rem] px-0.5 h-3 bg-turquoise-100 rounded-full text-extra-small-1 flex ' +
                          'items-center justify-center text-white font-gilroy-600 absolute ' +
                          '-top-1 left-2 w-fit'
                        }
                      >
                        {newOrders}
                      </div>
                    ) : null}
                  </div>
                </Link>

                <div className="hidden t-lg:flex gap-8">
                  {headItems.map(item => (
                    <Link
                      id={item?.id || ''}
                      to={item.route}
                      key={`top-${item.route}`}
                      onClick={closeMenu}
                      className={cn(
                        {
                          'text-turquoise-100': currentRoute?.route === item.route,
                          'text-grey-100 hover:text-turquoise-100':
                            currentRoute?.route !== item.route,
                        },
                        'text-sm font-gilroy-500 transition-full',
                      )}
                    >
                      {item.name}
                    </Link>
                  ))}
                </div>

                <BurgerIcon
                  className="t-lg:hidden w-8 h-8 fill-turquoise-100 cursor-pointer"
                  onClick={openMenu}
                />

                <div
                  className="items-center gap-2 hidden t-lg:flex cursor-pointer opacity-50 ml-20"
                  onClick={handleLogout}
                >
                  <div className="text-grey-100 font-gilroy-400 text-sm">Выйти</div>
                  <ExitIcon className="w-3 h-3 fill-grey-100" />
                </div>
              </div>

              {menuOpen ? (
                <div className="fixed w-full z-100 h-full inset-0 bg-grey-rgb-200" />
              ) : null}

              <div
                ref={ref}
                className={cn(
                  {
                    'left-full': !menuOpen,
                    'left-1/2 shadow-black-300': menuOpen,
                  },
                  't-lg:hidden w-1/2 h-full fixed top-0 z-110 transition-full bg-white-200 flex flex-col',
                )}
              >
                <CloseIcon
                  onClick={closeMenu}
                  className="w-8 h-8 fill-blue-100 cursor-pointer m-5 ml-auto"
                />
                {headItems.map(item => (
                  <Link
                    to={item.route}
                    key={item.route}
                    onClick={closeMenu}
                    className={cn(
                      {
                        'text-white bg-turquoise-100': currentRoute?.route === item.route,
                        'text-grey-100': currentRoute?.route !== item.route,
                      },
                      'w-full border-t border-grey-500',
                      styles,
                    )}
                  >
                    {item.name}
                  </Link>
                ))}
                <div className={cn('bg-blue-100 text-white', styles)} onClick={handleLogout}>
                  Выйти
                </div>
              </div>
            </>
          ) : null}
        </div>
        <div
          className={cn(
            {
              'h-16': searchActive,
              'h-0': !searchActive,
            },
            'transition-full flex items-center overflow-hidden px-4 t-lg:px-5',
          )}
        >
          <ControlledInput
            classNames={cn(
              {
                'opacity-1': searchActive,
                'opacity-0': !searchActive,
              },
              'w-full t-lg:w-[38rem] d-xs:w-[50rem] h-10 transition-full',
            )}
            onChange={handleChangeSearch}
            value={globalSearch}
            placeholder="Поиск"
          />
        </div>
      </div>
    </div>
  );
};
