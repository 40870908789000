import { FC, Fragment } from 'react';
import { Carousel } from 'src/components/Carousel';
import { BasketIconButton } from 'src/components/IconButton/BasketIconButton';
import { StatItemType } from 'src/config/types';

import { ReactComponent as PlusIcon } from 'src/assets/icons/kit/plus.svg';
import { ReactComponent as ImgIcon } from 'src/assets/icons/kit/img.svg';
import cn from 'classnames';

interface ProductStatsCarouselProps {
  openStatsModal?: (arg: string) => void;
  handleRemove: (index: number) => void;
  statId?: string;
  stat: StatItemType[];
}

export const ProductStatsCarousel: FC<ProductStatsCarouselProps> = ({
  handleRemove,
  openStatsModal,
  stat,
  statId,
}) => {
  const getSrc = (img: File | string) => {
    if (typeof img === 'string') {
      return img;
    }

    return URL.createObjectURL(img as File);
  };

  const onRemove = (index: number) => {
    handleRemove(index);
  };

  const handleOpen = () => {
    openStatsModal && openStatsModal(statId || '');
  };

  return (
    <>
      <Carousel itemWidth={80}>
        {stat.map((statItem, i) => {
          const key = `carousel-stat-${statItem.valueId}-image-${i}`;

          if ((!statItem?.image && !statItem?.valueName) || statItem.flag === 'delete') {
            return <Fragment key={key} />;
          }

          return (
            <div className="min-w-[5rem] w-20" key={key}>
              <div
                className={cn(
                  {
                    'bg-turquoise-500 border border-dashed border-grey-100 rounded':
                      !statItem.image,
                    'rounded-xl': statItem.image,
                  },
                  'w-20 h-20 flex items-center justify-center relative overflow-hidden',
                )}
              >
                <BasketIconButton
                  image="basket"
                  onClick={() => onRemove(i)}
                  variant="sm"
                  classNames="absolute top-1.5 right-1.5"
                />
                {statItem.image ? (
                  <img src={getSrc(statItem.image)} alt="" className="w-full h-full object-cover" />
                ) : null}
                {!statItem.image ? <ImgIcon className="w-8 h-8 fill-grey-100" /> : null}
              </div>
              {statItem?.valueName ? (
                <div className="w-full mt-2 text-center truncate text-grey-100 text-small font-gilroy-500">
                  {statItem.valueName}
                </div>
              ) : null}
            </div>
          );
        })}
        {openStatsModal ? (
          <div
            className={cn(
              'min-w-[5rem] w-20 h-20 flex items-center justify-center bg-grey-800 ',
              'rounded border border-dashed border-grey-100 cursor-pointer ',
              'transition-full hover:border-solid group',
            )}
            onClick={handleOpen}
          >
            <PlusIcon className="w-4 h-4 transition-full fill-grey-100 group-hover:fill-turquoise-100" />
          </div>
        ) : null}
      </Carousel>
    </>
  );
};
